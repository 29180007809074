import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chooseInstitution } from '../api/person';
import { Content } from '../components/content';
import { RemoteStatus } from '../types/remote';
import LoadingStatus from './loading-status';

interface InstitutionSelectorProps {
    jwt: string;
    person: LoginPerson;
    institutions: Institution[];
    activeInstitution?: Institution;
    onInstitutionSelected: (jwt: string, payload: LoginPayload, institution: Institution) => Promise<void>;
}

export function InstitutionSelector(props: InstitutionSelectorProps) {
    const { t } = useTranslation();
    const [status, setStatus] = useState<RemoteStatus>('idle');

    async function selectInstitution(institution: Institution) {
        setStatus('busy');
        const payload = await chooseInstitution(props.jwt, institution.institutionsSNO, institution.employeesSNO);
        await props.onInstitutionSelected(payload.JWT, payload, institution);
        setStatus('idle');
    }

    async function onSelectInstitution(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, institution: Institution) {
        e.preventDefault();
        await selectInstitution(institution);
    }

    if (props.institutions.length === 1) {
        return <></>;
    }

    return (
        <>
            <LoadingStatus status={status} />
            {status === 'idle' && (
                <>
                    <h2>{t('select-institution.header')}</h2>
                    {props.institutions.map((institution) => {
                        if (props.activeInstitution?.institutionsSNO === institution.institutionsSNO) {
                            return (
                                <Content key={'institution' + institution.institutionsSNO}>
                                    <i className="fa fa-home"></i> {institution.institutionName} (
                                    {t('institution-access.role.' + institution.employeeRole)}) -{' '}
                                    {t('institution-access.selected')}
                                </Content>
                            );
                        }
                        return (
                            <Content key={'institution' + institution.institutionsSNO}>
                                <a href="#/" onClick={(e) => onSelectInstitution(e, institution)}>
                                    <i className="fa fa-home"></i> {institution.institutionName} (
                                    {t('institution-access.role.' + institution.employeeRole)})
                                </a>
                            </Content>
                        );
                    })}
                </>
            )}
        </>
    );
}
