import { FunctionComponent } from 'react';
import ErrorComponent from './error-component';
import Spinner from './spinner';
import { RemoteStatus } from '../types/remote';

interface Props {
    status: RemoteStatus;
}

/**
 * This component is shown at the top of the page for pages with API call to indicate its status - loading or error.
 * It is not bias with whether there are data being loaded or not.
 */
const LoadingStatus: FunctionComponent<Props> = ({ status }) => {
    if (status === 'busy') {
        return (
            <div className="text-center">
                <Spinner />
            </div>
        );
    } else if (status === 'error') {
        return <ErrorComponent />;
    } else {
        return null;
    }
};

export default LoadingStatus;
