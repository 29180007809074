import cx from 'classnames';
import { FC } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
    exception?: Exception;
    centre?: boolean;
    full?: boolean;
    errorMessage?: string;
}

const ErrorComponent: FC<Props> = ({ className, exception, centre = true, full = false, errorMessage }) => {
    return full ? (
        <AlertBox exception={exception} />
    ) : (
        <Row className={cx({ 'justify-content-center': centre }, { [className!]: !!className })}>
            <Col xs={12} sm={8} md={6} lg={5}>
                <AlertBox exception={exception} message={errorMessage} />
            </Col>
        </Row>
    );
};

export default ErrorComponent;

const AlertBox: FC<{ exception?: Exception; message?: string }> = ({ exception, message }) => {
    const { t } = useTranslation();
    return (
        <Alert
            variant="danger"
            className={cx(
                'text-danger',
                { 'gap-2': !exception },
                { 'gap-3': !!exception },
                'justify-content-center',
                'align-items-center'
            )}
        >
            <i className="fa fa-circle-exclamation fa-lg" />
            <div>
                {message ? message : t('technical-error')}
                {exception?.requestId && <div>ID: {exception.requestId}</div>}
            </div>
        </Alert>
    );
};
