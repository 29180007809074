import { addDays, addSeconds, startOfDay } from 'date-fns';
import { groupBy } from 'lodash-es';
import { createContext, FunctionComponent, Reducer, useCallback, useContext, useReducer } from 'react';
import { fetchPeriodEmployees } from '../api/norm-periods';
import {
    fetchNormTimeHeadCountByNormPeriod,
    getOpenersAndClosersShiftsForRange,
    getWinningTimeForRange,
    normTimeGetAllFromPeriod,
} from '../api/norm-time';
import { normTimeNotificationsGetAllFromPeriod } from '../api/norm-time-notifications';
import { NormTimeNotification } from '../api/norm-time-notifications.type';
import { fetchAllTimeCategoriesWithTypes } from '../api/norm-time-types';
import { NormTimeHeadCountDTO, OpenersAndClosersShifts, WorkplanGantt } from '../api/norm-time.type';
import { getWinningTimeTotalsFromPeriod } from '../api/normworker-print-simple';
import { WorkerTotals } from '../api/normworker-print-simple.type';
import { getRemainingInstitutionTimeForNormWorkers } from '../api/planning';
import { getAllTeams } from '../api/teams';
import { useAppContext } from '../app-context';
import useMounted from '../hooks/use-mounted';
import log from '../logging/logging';
import { NormTimeCategory, NormTimeType } from '../types/norm-time-types';
import { Remote } from '../types/remote';
import { NormWorkerRemainingHours } from '../types/workplan';
import { notImplemented } from '../util/common';
import { getStartAndEndOfWeekInPeriod } from '../util/dates/get-start-and-end-of-week-in-period';
import { formatToIsoDate } from '../util/datetime';

interface ContextType {
    planning: PlanningData;
    actions: {
        fetchAllData: (period: Period) => Promise<void>;
        fetchWorkplanGanttForAWeek: (period: Period, firstDayOfWeek: Date) => Promise<WorkplanGantt>;
        fetchWorkplanGanttForWeekRangeNormWorker: (
            period: Period,
            firstDayOfStartWeek: Date,
            firstDayOfEndWeek: Date,
            normWorkersSNO: number
        ) => Promise<WorkplanGantt>;
        fetchWorkplanGanttForWeekRange: (
            period: Period,
            firstDayOfStartWeek: Date,
            firstDayOfEndWeek: Date
        ) => Promise<WorkplanGantt>;
        fetchAllNormTimesFromWeek: (period: Period, firstDayOfWeek: string, lastDayOfWeek: string) => Promise<void>;
        upsertNormTime: (normTime: NormTime) => void;
        removeNormTime: (normTime: NormTime['normTimeSNO']) => void;
        upsertNormWorker: (normWorkersSNO: NormWorker['normWorkersSNO'], normTeamsSNO: Team['normTeamsSNO']) => void;
        setSelectedNormTimeType: (NormTimeType: NormTimeType) => void;
        refreshHeadCount: (period: Period, firstDayOfWeek: Date) => Promise<NormTimeHeadCountDTO>;
        refreshHeadCountRange: (period: Period, startDate: Date, endDate: Date) => Promise<NormTimeHeadCountDTO>;
        fetchDataForWorkplanGantt: (
            period: Period,
            firstDayOfWeek: Date
        ) => Promise<{
            data: WorkplanGantt;
            headcount: NormTimeHeadCountDTO;
            openersAndClosersShifts: OpenersAndClosersShifts;
        }>;
        fetchDataForWorkplanGanttRange: (
            period: Period,
            startDate: Date,
            endDate: Date
        ) => Promise<{
            data: WorkplanGantt;
            headcount: NormTimeHeadCountDTO;
            openersAndClosersShifts: OpenersAndClosersShifts;
        }>;
        resetWorkplanGanttCache: () => void;
        fetchWorkersRemainingHours: (normWorkersSNOs: number[]) => Promise<void>;
        fetchWorkersTotals: (period: Period, firstDayOfWeek: Date, normWorkersSNOs: number[]) => Promise<void>;
        setSelectedNormTimeNotifications: (normTimeNotifications: NormTimeNotification[] | undefined) => Promise<void>;
        fetchNormTimeNotifications: (period: Period, dateStart: Date, dateEnd: Date) => Promise<NormTimeNotification[]>;
    };
}

interface PlanningData {
    // Contains all time categories in the norm period, mostly for look-up purpose
    normTimeCategories: NormTimeCategory[] | undefined;
    // Contains only active time categories in the norm period, mostly showing/selection purpose
    activeNormTimeCategories: NormTimeCategory[] | undefined;
    // Contains all time types in the norm period, mostly for look-up purpose
    normTimeTypes: NormTimeType[] | undefined;
    selectedNormTimeType: NormTimeType | undefined;
    normWorkers: NormWorker[] | undefined;
    teams: Team[] | undefined;
    normTimes: NormTime[] | undefined;
    // Just a look up object, so there's no different between undefined and empty object
    normTimeNotificationsByNormWorkerAndDate: Record<string, NormTimeNotification[]>;
    workplanGantt: Remote<WorkplanGantt | undefined>;
    resourceCount: NormTimeHeadCountDTO;
    remainingHoursByNormWorkersSNO: Map<number, Remote<NormWorkerRemainingHours>>;
    totalsByNormWorkersSNO: Map<number, Remote<WorkerTotals>>;
    selectedNormTimeNotifications: NormTimeNotification[] | undefined;
    openersAndClosersShifts: OpenersAndClosersShifts;
}

type PlanningAction =
    | {
          type: 'set-timetypes';
          payload: NormTimeCategory[];
      }
    | {
          type: 'set-norm-workers';
          payload: NormWorker[];
      }
    | {
          type: 'set-teams-in-period';
          payload: Team[];
      }
    | {
          type: 'set-norm-time';
          payload: NormTime[];
      }
    | {
          type: 'set-norm-time-notifications';
          payload: NormTimeNotification[];
      }
    | {
          type: 'set-active-norm-time-type';
          payload: NormTimeType;
      }
    | {
          type: 'load-workplan-gantt';
      }
    | {
          type: 'set-workplan-gantt';
          payload: WorkplanGantt | undefined;
      }
    | {
          type: 'workplan-gantt-error';
          message: string;
      }
    | {
          type: 'set-head-count';
          payload: NormTimeHeadCountDTO;
      }
    | {
          type: 'load-remaining-time';
          payload: number[];
      }
    | {
          type: 'set-remaining-time';
          payload: NormWorkerRemainingHours[];
      }
    | {
          type: 'error-remaining-time';
          payload: number[];
      }
    | {
          type: 'load-totals-time';
          payload: number[];
      }
    | {
          type: 'set-totals-time';
          payload: WorkerTotals[];
      }
    | {
          type: 'error-totals-time';
          payload: number[];
      }
    | {
          type: 'set-selected-norm-time-notifications';
          payload: NormTimeNotification[] | undefined;
      }
    | {
          type: 'set-openers-and-closers-shifts';
          payload: OpenersAndClosersShifts;
      };

const initialState: PlanningData = {
    normTimeCategories: undefined,
    activeNormTimeCategories: undefined,
    normTimeTypes: undefined,
    selectedNormTimeType: undefined,
    normWorkers: undefined,
    teams: undefined,
    normTimes: undefined,
    normTimeNotificationsByNormWorkerAndDate: {},
    workplanGantt: {
        status: 'idle',
        payload: undefined,
    },
    resourceCount: {},
    remainingHoursByNormWorkersSNO: new Map(),
    totalsByNormWorkersSNO: new Map(),
    selectedNormTimeNotifications: undefined,
    openersAndClosersShifts: { openers: [], closers: [] },
};

/**
 * Create a react context with planning data
 * and the respective function to update the data in a controlled manner
 */
const Context = createContext<ContextType>({
    planning: initialState,
    actions: {
        fetchAllData: notImplemented,
        fetchWorkplanGanttForAWeek: notImplemented,
        fetchWorkplanGanttForWeekRangeNormWorker: notImplemented,
        fetchWorkplanGanttForWeekRange: notImplemented,
        fetchAllNormTimesFromWeek: notImplemented,
        upsertNormTime: notImplemented,
        removeNormTime: notImplemented,
        upsertNormWorker: notImplemented,
        setSelectedNormTimeType: notImplemented,
        refreshHeadCount: notImplemented,
        refreshHeadCountRange: notImplemented,
        fetchDataForWorkplanGantt: notImplemented,
        fetchDataForWorkplanGanttRange: notImplemented,
        resetWorkplanGanttCache: notImplemented,
        fetchWorkersRemainingHours: notImplemented,
        fetchWorkersTotals: notImplemented,
        setSelectedNormTimeNotifications: notImplemented,
        fetchNormTimeNotifications: notImplemented,
    },
});

const interimCacheForWorkplanGanttDataByNormPeriodAndWeek = new Map<
    string,
    [WorkplanGantt, NormTimeHeadCountDTO, OpenersAndClosersShifts]
>();

/**
 * Update planning data based on actions.
 * @param state Previous state
 * @param action The action dispatched
 * @returns New state
 */
const reducer: Reducer<PlanningData, PlanningAction> = (state, action) => {
    switch (action.type) {
        case 'set-timetypes':
            const normTimeCategories = action.payload;

            let activeNormTimeCategories: NormTimeCategory[] = [];

            for (let category of normTimeCategories) {
                // only get time types that will be shown in work plan
                const activeTimeTypes = category.timeTypes.filter((timeType) => timeType.showInWorkplan === 1);

                // if a category has no time type to be shown, skip it altogether
                if (activeTimeTypes.length === 0) {
                    continue;
                }

                activeNormTimeCategories.push({
                    ...category,
                    timeTypes: activeTimeTypes,
                });
            }

            return {
                ...state,
                normTimeTypes: normTimeCategories.flatMap((category) => category.timeTypes),
                normTimeCategories,
                activeNormTimeCategories,
            };
        case 'set-norm-workers':
            return {
                ...state,
                normWorkers: action.payload,
            };
        case 'set-teams-in-period':
            return {
                ...state,
                teams: action.payload,
            };
        case 'set-norm-time':
            const normTimesWithoutVirtualShift = action.payload.filter((normTime) => normTime.origin !== 'virtual');
            const virtualNormTimes = getVirtualOffWorkNormTimes(normTimesWithoutVirtualShift);
            return {
                ...state,
                normTimes: [...normTimesWithoutVirtualShift, ...virtualNormTimes].sort(
                    (a, b) => a.isWorking - b.isWorking // ascending order - 0 to go before 1
                ),
            };
        case 'set-norm-time-notifications':
            return {
                ...state,
                normTimeNotificationsByNormWorkerAndDate: groupBy(
                    action.payload,
                    ({ date, normWorkersSNO }) => `${normWorkersSNO}-${date.getTime()}`
                ),
            };
        case 'set-active-norm-time-type':
            return {
                ...state,
                selectedNormTimeType: action.payload,
            };
        case 'load-workplan-gantt':
            return {
                ...state,
                workplanGantt: {
                    status: 'busy',
                },
            };
        case 'set-workplan-gantt':
            return {
                ...state,
                workplanGantt: {
                    status: 'idle',
                    payload: action.payload,
                },
            };
        case 'workplan-gantt-error':
            return {
                ...state,
                workplanGantt: {
                    status: 'error',
                    message: action.message,
                    payload: undefined,
                },
            };
        case 'set-head-count':
            return {
                ...state,
                resourceCount: action.payload,
            };
        case 'load-remaining-time': {
            const newMap = new Map(state.remainingHoursByNormWorkersSNO.entries());
            action.payload.forEach((normWorkersSNO) => {
                newMap.set(normWorkersSNO, { status: 'busy' });
            });
            return {
                ...state,
                remainingHoursByNormWorkersSNO: newMap,
            };
        }
        case 'set-remaining-time': {
            const newMap = new Map(state.remainingHoursByNormWorkersSNO.entries());
            action.payload.forEach((remainingHoursObj) => {
                newMap.set(remainingHoursObj.normWorkersSNO, { status: 'idle', payload: remainingHoursObj });
            });
            return {
                ...state,
                remainingHoursByNormWorkersSNO: newMap,
            };
        }
        case 'error-remaining-time': {
            const newMap = new Map(state.remainingHoursByNormWorkersSNO.entries());
            action.payload.forEach((normWorkersSNO) => {
                newMap.set(normWorkersSNO, { status: 'error', message: 'Unable to load remaining time' });
            });
            return {
                ...state,
                remainingHoursByNormWorkersSNO: newMap,
            };
        }
        case 'load-totals-time': {
            const newMap = new Map(state.totalsByNormWorkersSNO.entries());
            action.payload.forEach((normWorkersSNO) => {
                newMap.set(normWorkersSNO, { status: 'busy' });
            });
            return {
                ...state,
                totalsByNormWorkersSNO: newMap,
            };
        }
        case 'set-totals-time': {
            const newMap = new Map(state.totalsByNormWorkersSNO.entries());
            action.payload.forEach((remainingHoursObj) => {
                newMap.set(remainingHoursObj.normWorkersSNO, { status: 'idle', payload: remainingHoursObj });
            });
            return {
                ...state,
                totalsByNormWorkersSNO: newMap,
            };
        }
        case 'error-totals-time': {
            const newMap = new Map(state.totalsByNormWorkersSNO.entries());
            action.payload.forEach((normWorkersSNO) => {
                newMap.set(normWorkersSNO, { status: 'error', message: 'Unable to load remaining time' });
            });
            return {
                ...state,
                totalsByNormWorkersSNO: newMap,
            };
        }
        case 'set-selected-norm-time-notifications':
            return {
                ...state,
                selectedNormTimeNotifications: action.payload,
            };
        case 'set-openers-and-closers-shifts':
            return {
                ...state,
                openersAndClosersShifts: action.payload,
            };
    }
};

/**
 * Wrap around react components and provide its children with planning data.
 */
const PlanningContext: FunctionComponent = ({ children }) => {
    const mounted = useMounted();
    const [planning, dispatch] = useReducer(reducer, initialState);
    const state = useAppContext();

    const fetchAllData: ContextType['actions']['fetchAllData'] = useCallback(
        async (period) => {
            return Promise.all([
                fetchPeriodEmployees(state.jwt, period),
                fetchAllTimeCategoriesWithTypes(period.normPeriodsSNO),
                getAllTeams(state.jwt, period.normPeriodsSNO),
            ])
                .then(([employeesInPeriod, normTimeCategories, teams]) => {
                    if (mounted.current) {
                        dispatch({ type: 'set-norm-workers', payload: employeesInPeriod });
                        dispatch({ type: 'set-timetypes', payload: normTimeCategories });
                        dispatch({ type: 'set-teams-in-period', payload: teams });
                    }
                })
                .catch((e) => {
                    log.error(e);
                });
        },
        [mounted, state.jwt]
    );

    const fetchOpenersAndCloserForAWeek = useCallback(async (period, firstDayOfWeek) => {
        const from = startOfDay(+firstDayOfWeek < +period.start ? period.start : firstDayOfWeek);
        const to = addSeconds(addDays(from, 7), -1);

        const payload = await getOpenersAndClosersShiftsForRange(
            period.normPeriodsSNO,
            from,
            to > period.end ? period.end : to,
            null
        );

        dispatch({ type: 'set-openers-and-closers-shifts', payload });

        return payload;
    }, []);
    const fetchOpenersAndCloserForRange = useCallback(async (period, start, end) => {
        const payload = await getOpenersAndClosersShiftsForRange(period.normPeriodsSNO, start, end, null);

        dispatch({ type: 'set-openers-and-closers-shifts', payload });

        return payload;
    }, []);

    const fetchWorkplanGanttForAWeek: ContextType['actions']['fetchWorkplanGanttForAWeek'] = useCallback(
        async (period, firstDayOfWeek) => {
            const from = startOfDay(+firstDayOfWeek < +period.start ? period.start : firstDayOfWeek);
            const to = addSeconds(addDays(from, 7), -1);

            dispatch({ type: 'load-workplan-gantt' });

            try {
                const [winningTimeResponse, teams] = await Promise.all([
                    getWinningTimeForRange(period.normPeriodsSNO, from, to > period.end ? period.end : to, null),
                    getAllTeams(undefined, period.normPeriodsSNO),
                ]);

                const normalisedResponse = restructureTeams(winningTimeResponse);
                const sortedWorkplanGantt = sortByTeams(
                    normalisedResponse,
                    teams.reduce((acc, val) => {
                        acc.set(val.normTeamsSNO, val);
                        return acc;
                    }, new Map<number, Team>())
                );

                dispatch({ type: 'set-workplan-gantt', payload: sortedWorkplanGantt });
                return sortedWorkplanGantt;
            } catch (e) {
                dispatch({ type: 'workplan-gantt-error', message: (e as any).toString() });
                throw e;
            }
        },
        []
    );

    const fetchWorkplanGanttForWeekRangeNormWorker: ContextType['actions']['fetchWorkplanGanttForWeekRangeNormWorker'] =
        useCallback(async (period, firstDayOfStartWeek, firstDayOfEndWeek, normWorkersSNO) => {
            const from = startOfDay(+firstDayOfStartWeek < +period.start ? period.start : firstDayOfStartWeek);
            const toFirstDay = startOfDay(+firstDayOfEndWeek > +period.end ? period.end : firstDayOfEndWeek);
            const to = addSeconds(addDays(toFirstDay, 7), -1);

            dispatch({ type: 'load-workplan-gantt' });

            try {
                const response = await getWinningTimeForRange(
                    period.normPeriodsSNO,
                    from,
                    to > period.end ? period.end : to,
                    normWorkersSNO
                );

                const normalisedResponse = restructureTeams(response);
                dispatch({ type: 'set-workplan-gantt', payload: normalisedResponse });
                return normalisedResponse;
            } catch (e) {
                dispatch({ type: 'workplan-gantt-error', message: (e as any).toString() });
                throw e;
            }
        }, []);

    const fetchWorkplanGanttForWeekRange: ContextType['actions']['fetchWorkplanGanttForWeekRange'] = useCallback(
        async (period, firstDayOfStartWeek, firstDayOfEndWeek) => {
            const from = startOfDay(+firstDayOfStartWeek < +period.start ? period.start : firstDayOfStartWeek);
            const toFirstDay = startOfDay(+firstDayOfEndWeek > +period.end ? period.end : firstDayOfEndWeek);
            const to = addSeconds(addDays(toFirstDay, 7), -1);

            dispatch({ type: 'load-workplan-gantt' });

            try {
                const response = await getWinningTimeForRange(
                    period.normPeriodsSNO,
                    from,
                    to > period.end ? period.end : to,
                    null
                );

                const normalisedResponse = restructureTeams(response);

                dispatch({ type: 'set-workplan-gantt', payload: normalisedResponse });
                return normalisedResponse;
            } catch (e) {
                dispatch({ type: 'workplan-gantt-error', message: (e as any).toString() });
                throw e;
            }
        },
        []
    );

    const fetchAllNormTimesFromWeek: ContextType['actions']['fetchAllNormTimesFromWeek'] = useCallback(
        async (period, firstDayOfWeek, lastDayOfWeek) => {
            //added  because API can't handle a date outside of the norm-period
            const firstDayDate = new Date(firstDayOfWeek);
            const lastDayDate = new Date(lastDayOfWeek);
            const effectiveStartDate = firstDayDate < period.start ? formatToIsoDate(period.start) : firstDayOfWeek;
            const effectiveEndDate = lastDayDate > period.end ? formatToIsoDate(period.end) : lastDayOfWeek;

            return Promise.all([
                normTimeGetAllFromPeriod(period.normPeriodsSNO, firstDayOfWeek, lastDayOfWeek),
                normTimeNotificationsGetAllFromPeriod(period.normPeriodsSNO, effectiveStartDate, effectiveEndDate),
            ])
                .then(([normTimes, normTimeNotifications]) => {
                    if (mounted.current) {
                        dispatch({ type: 'set-norm-time', payload: normTimes });
                        dispatch({ type: 'set-norm-time-notifications', payload: normTimeNotifications });
                    }
                })
                .catch((e) => {
                    log.error(e);
                });
        },
        [mounted]
    );

    const upsertNormTime: ContextType['actions']['upsertNormTime'] = (normTime) => {
        if (!planning?.normTimes) {
            console.warn('Are you sure `planning?.normTimes` should be null?');
            return;
        }

        const idx = planning.normTimes.findIndex((wh) => wh.normTimeSNO === normTime.normTimeSNO);

        if (idx === -1) {
            // It's a newly created norm time
            const newNormTimes = [...planning.normTimes, normTime];
            dispatch({ type: 'set-norm-time', payload: newNormTimes });
        } else {
            const newNormTimes = planning.normTimes.slice(); // shallow clone the array
            // splice mutates the array, so shallow cloning the original array to avoid state mutation.
            newNormTimes.splice(idx, 1);
            newNormTimes.push(normTime);
            newNormTimes.sort((a, b) => b.normTimeTypesSNO - a.normTimeTypesSNO);
            dispatch({ type: 'set-norm-time', payload: newNormTimes });
        }
    };

    const removeNormTime: ContextType['actions']['removeNormTime'] = (deletedId) => {
        if (!planning?.normTimes) {
            console.warn('Are you sure `planning?.normTimes` should be null?');
            return;
        }

        const newNormTimes = planning.normTimes.filter((wh) => wh.normTimeSNO !== deletedId); // shallow clone the array

        dispatch({ type: 'set-norm-time', payload: newNormTimes });
    };

    const upsertNormWorker: ContextType['actions']['upsertNormWorker'] = (normWorkersSNO, normTeamsSNO) => {
        if (!planning?.normWorkers) {
            console.warn('Are you sure `planning?.normWorkers` should be null?');
            return;
        }
        const normWorker = planning?.normWorkers.find((normWorker) => normWorker.normWorkersSNO === normWorkersSNO);
        if (!normWorker) {
            console.warn('There is no norm worker to update');
            return;
        }
        normWorker.normTeamsSNOPrimary = normTeamsSNO;
        const idx = planning?.normWorkers.findIndex((normWorker) => normWorker.normWorkersSNO === normWorkersSNO);
        const newNormWorkers = planning?.normWorkers.slice(); // shallow clone the array
        // splice mutates the array, so shallow cloning the original array to avoid state mutation.
        newNormWorkers.splice(idx, 1);
        newNormWorkers.push(normWorker);
        dispatch({ type: 'set-norm-workers', payload: newNormWorkers });
    };

    const setSelectedNormTimeType: ContextType['actions']['setSelectedNormTimeType'] = (selectedNormTimeType) => {
        dispatch({
            type: 'set-active-norm-time-type',
            payload: selectedNormTimeType,
        });
    };

    const refreshHeadCount: ContextType['actions']['refreshHeadCount'] = useCallback(
        async (period, start) => {
            const from = startOfDay(+start < +period.start ? period.start : start);
            const to = addSeconds(addDays(from, 7), -1);

            try {
                const normTimeHeadCount = await fetchNormTimeHeadCountByNormPeriod(
                    state.jwt,
                    period.normPeriodsSNO,
                    from,
                    to
                );
                dispatch({ type: 'set-head-count', payload: normTimeHeadCount });
                return normTimeHeadCount;
            } catch (e) {
                log.error((e as any).toString());
                dispatch({ type: 'set-head-count', payload: {} });
                return {};
            }
        },
        [state.jwt]
    );
    const refreshHeadCountRange: ContextType['actions']['refreshHeadCountRange'] = useCallback(
        async (period, start, end) => {
            try {
                const normTimeHeadCount = await fetchNormTimeHeadCountByNormPeriod(
                    state.jwt,
                    period.normPeriodsSNO,
                    start,
                    end
                );
                dispatch({ type: 'set-head-count', payload: normTimeHeadCount });
                return normTimeHeadCount;
            } catch (e) {
                log.error((e as any).toString());
                dispatch({ type: 'set-head-count', payload: {} });
                return {};
            }
        },
        [state.jwt]
    );

    const fetchDataForWorkplanGantt: ContextType['actions']['fetchDataForWorkplanGantt'] = useCallback(
        async (period, start) => {
            const cacheKey = `${period.normPeriodsSNO}-${+start}`;

            const [data, headcount, openersAndClosersShifts] = interimCacheForWorkplanGanttDataByNormPeriodAndWeek.has(
                cacheKey
            )
                ? interimCacheForWorkplanGanttDataByNormPeriodAndWeek.get(cacheKey)!
                : await Promise.all([
                      fetchWorkplanGanttForAWeek(period, start),
                      refreshHeadCount(period, start),
                      fetchOpenersAndCloserForAWeek(period, start),
                  ]);

            // intentionally cache one and only one key
            // so that when user selects a new week, the cache for previous week will be invalidated
            interimCacheForWorkplanGanttDataByNormPeriodAndWeek.clear();
            interimCacheForWorkplanGanttDataByNormPeriodAndWeek.set(cacheKey, [
                data,
                headcount,
                openersAndClosersShifts,
            ]);

            return { data, headcount, openersAndClosersShifts };
        },
        [fetchOpenersAndCloserForAWeek, fetchWorkplanGanttForAWeek, refreshHeadCount]
    );
    const fetchDataForWorkplanGanttRange: ContextType['actions']['fetchDataForWorkplanGanttRange'] = useCallback(
        async (period, start, end) => {
            const cacheKey = `${period.normPeriodsSNO}-${+start}`;

            const [data, headcount, openersAndClosersShifts] = interimCacheForWorkplanGanttDataByNormPeriodAndWeek.has(
                cacheKey
            )
                ? interimCacheForWorkplanGanttDataByNormPeriodAndWeek.get(cacheKey)!
                : await Promise.all([
                      fetchWorkplanGanttForWeekRange(period, start, end),
                      refreshHeadCountRange(period, start, end),
                      fetchOpenersAndCloserForRange(period, start, end),
                  ]);

            // intentionally cache one and only one key
            // so that when user selects a new week, the cache for previous week will be invalidated
            interimCacheForWorkplanGanttDataByNormPeriodAndWeek.clear();
            interimCacheForWorkplanGanttDataByNormPeriodAndWeek.set(cacheKey, [
                data,
                headcount,
                openersAndClosersShifts,
            ]);

            return { data, headcount, openersAndClosersShifts };
        },
        [fetchOpenersAndCloserForRange, fetchWorkplanGanttForWeekRange, refreshHeadCountRange]
    );

    const resetWorkplanGanttCache: ContextType['actions']['resetWorkplanGanttCache'] = useCallback(() => {
        interimCacheForWorkplanGanttDataByNormPeriodAndWeek.clear();
    }, []);

    const fetchWorkersRemainingHours: ContextType['actions']['fetchWorkersRemainingHours'] = useCallback(
        async (normWorkersSNO) => {
            const retryFetch = async (workersToRetry: number[], attempts = 10): Promise<void> => {
                dispatch({ type: 'load-remaining-time', payload: workersToRetry });
                try {
                    const payload = await getRemainingInstitutionTimeForNormWorkers(workersToRetry);
                    dispatch({ type: 'set-remaining-time', payload });

                    const workersWithNullHours = payload
                        .filter(
                            (worker: { remainingInstitutionHours: number | null }) =>
                                worker.remainingInstitutionHours === null
                        )
                        .map((worker: { normWorkersSNO: number }) => worker.normWorkersSNO);

                    if (workersWithNullHours.length > 0 && attempts > 0) {
                        const timeout = 11 - attempts * 2;
                        await new Promise((resolve) => setTimeout(resolve, timeout * 1000));
                        return retryFetch(workersWithNullHours, attempts - 1);
                    }
                } catch (error) {
                    dispatch({ type: 'error-remaining-time', payload: workersToRetry });
                }
            };

            await retryFetch(normWorkersSNO);
        },
        []
    );

    const fetchWorkersTotals: ContextType['actions']['fetchWorkersTotals'] = useCallback(
        async (period, firstDayOfWeek, normWorkersSNO) => {
            // We try for a total of 3 times
            const retryFetch = async (workersToRetry: number[], attempts = 10): Promise<void> => {
                dispatch({ type: 'load-totals-time', payload: workersToRetry });
                try {
                    const { startDate: startOfWeek, endDate: endOfWeek } = getStartAndEndOfWeekInPeriod(
                        firstDayOfWeek,
                        period
                    );

                    const response = await getWinningTimeTotalsFromPeriod(
                        period.normPeriodsSNO,
                        startOfWeek,
                        endOfWeek,
                        workersToRetry
                    );
                    dispatch({ type: 'set-totals-time', payload: response });
                    const workersWithNullAverages = response
                        .filter((worker: WorkerTotals) => {
                            const weekData = worker.weeks;
                            return weekData[formatToIsoDate(firstDayOfWeek)].average === null;
                        })
                        .map((worker: WorkerTotals) => worker.normWorkersSNO);

                    if (workersWithNullAverages.length > 0 && attempts > 0) {
                        const timeout = 11 - attempts * 2;
                        await new Promise((resolve) => setTimeout(resolve, timeout * 1000));
                        return retryFetch(workersWithNullAverages, attempts - 1);
                    }
                } catch (error) {
                    dispatch({ type: 'error-totals-time', payload: workersToRetry });
                }
            };

            await retryFetch(normWorkersSNO);
        },
        []
    );

    const setSelectedNormTimeNotifications: ContextType['actions']['setSelectedNormTimeNotifications'] = useCallback(
        async (normTimeNotifications) => {
            dispatch({ type: 'set-selected-norm-time-notifications', payload: normTimeNotifications });
        },
        []
    );

    const fetchNormTimeNotifications: ContextType['actions']['fetchNormTimeNotifications'] = useCallback(
        async (period, dateStart, dateEnd) => {
            const effectiveStartDate = dateStart < period.start ? period.start : dateStart;
            const effectiveEndDate = dateEnd > period.end ? period.end : dateEnd;
            const normTimeNotifications = await normTimeNotificationsGetAllFromPeriod(
                period.normPeriodsSNO,
                formatToIsoDate(effectiveStartDate),
                formatToIsoDate(effectiveEndDate)
            );
            dispatch({ type: 'set-norm-time-notifications', payload: normTimeNotifications });
            return normTimeNotifications;
        },
        []
    );

    return (
        <Context.Provider
            value={{
                planning,
                actions: {
                    fetchAllData,
                    fetchWorkplanGanttForAWeek,
                    fetchWorkplanGanttForWeekRangeNormWorker,
                    fetchWorkplanGanttForWeekRange,
                    fetchAllNormTimesFromWeek,
                    upsertNormTime,
                    removeNormTime,
                    upsertNormWorker,
                    setSelectedNormTimeType,
                    refreshHeadCount,
                    refreshHeadCountRange,
                    fetchDataForWorkplanGantt,
                    fetchDataForWorkplanGanttRange,
                    resetWorkplanGanttCache,
                    fetchWorkersRemainingHours,
                    fetchWorkersTotals,
                    setSelectedNormTimeNotifications,
                    fetchNormTimeNotifications,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default PlanningContext;

export const usePlanningContext = () => useContext(Context);

// Utility functions

const restructureTeams = (workplanGantt: WorkplanGantt): WorkplanGantt => {
    const flattenedWinningTimes = workplanGantt.flatMap((entry) => {
        const { winningTime, employeeName, ...rest } = entry;
        return winningTime.map((winning) => ({
            ...rest,
            outerEmployeeName: employeeName,
            ...winning,
        }));
    });

    const winningTimeByTeamWorker = groupBy(
        flattenedWinningTimes,
        ({ normTeamsSNO, employeesSNO }) => `${normTeamsSNO}-${employeesSNO}`
    );

    const workplanGanttWithCorrectTeams = Object.values(winningTimeByTeamWorker).map((winningTime) => ({
        employeesSNO: winningTime[0].employeesSNO,
        normTeamsSNO: winningTime[0].normTeamsSNO,
        employeeRole: winningTime[0].employeeRole,
        employeeName: winningTime[0].outerEmployeeName,
        teamName: winningTime[0].normTeamsName,
        winningTime,
    }));

    return workplanGanttWithCorrectTeams;
};

const sortByTeams = (workplanGantt: WorkplanGantt, teamBySNO: Map<number, Team>): WorkplanGantt => {
    return [...workplanGantt].sort((a, b) => {
        let x: Team | undefined;
        let y: Team | undefined;
        if (!a.normTeamsSNO || !(x = teamBySNO.get(a.normTeamsSNO))) {
            return 1;
        }

        if (!b.normTeamsSNO || !(y = teamBySNO.get(b.normTeamsSNO))) {
            return -1;
        }

        return x.sort - y.sort;
    });
};

const getVirtualOffWorkNormTimes = (normTimes: NormTime[]) => {
    const teamsSNOSetByNormWorkersSNO = normTimes.reduce((map, { normWorkersSNO, normTeamsSNO }) => {
        if (map.has(normWorkersSNO)) {
            map.get(normWorkersSNO)!.add(normTeamsSNO);
        } else {
            map.set(normWorkersSNO, new Set([normTeamsSNO]));
        }
        return map;
    }, new Map<number, Set<number>>());

    // get only norm-worker appearing in more than a team
    const normWorkersInMoreThanATeam = new Map(
        Array.from(teamsSNOSetByNormWorkersSNO.entries()).filter(([, normTeamsSNOSet]) => normTeamsSNOSet.size > 1)
    );

    // if none of the worker is in more a team, skip all logic
    if (normWorkersInMoreThanATeam.size === 0) {
        return [];
    }

    const virtualOffWorkNormTimes: NormTime[] = normTimes
        .filter((normTime) => normTime.isWorking === 0)
        .filter((normTime) => normWorkersInMoreThanATeam.has(normTime.normWorkersSNO)) // only if they're assigned to a norm worker who's in more than 1 team
        .flatMap((normTime) =>
            Array.from(normWorkersInMoreThanATeam.get(normTime.normWorkersSNO)!.values())
                .filter((normTeamsSNO) => normTeamsSNO !== normTime.normTeamsSNO) // get every team except current one
                .map((normTeamsSNO) => ({
                    // id: uuid(), // brand new uuid
                    // ...normTime,
                    // normTeamsSNO,
                    // resource: `${normTeamsSNO}-${normTime.normWorkersSNO}`,
                    ...normTime,
                    normTimeSNO: Date.now(),
                    normTeamsSNO,
                    origin: 'virtual',
                }))
        );

    return virtualOffWorkNormTimes;
};
